<template>
  <div>
      <v-card outlined tile>
        <v-list>
          <v-list-item>
            <v-text-field label="Name" :value="academy.name"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-select
              :items="categoryItems"
              label="학원유형"
              :value="academy.type"
            ></v-select>
          </v-list-item>
          <v-list-item>
            <v-text-field label="Sticker Cnt" :value="academy.stickerCnt"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field label="Sticker Reward" :value="academy.stickerReward"></v-text-field>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer/>
          <v-btn tile outlined color="success" @click="updateAcademy"><v-icon>mdi-content-save-edit</v-icon>수정</v-btn>
        </v-card-actions>
      </v-card>

    <v-snackbar
      v-model="snackbar"
      color="info"
    >
      수정 완료!

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: ['academyId', 'academy'],
  data () {
    return {
      categoryItems: [
        '학원', '교습소', '공부방', '기타'
      ],
      snackbar: false
    }
  },
  methods: {
    async updateAcademy () {
      if (!this.academyId) return

      const updateForm = {
        name: this.academy.name,
        type: this.academy.type,
        stickerCnt: Number(this.academy.stickerCnt),
        stickerReward: Number(this.academy.stickerReward)
      }

      // 변경된 스티커 갯수 - 1 확인
      const sn = await this.$firebase.firestore().collection('students')
        .where('academy', '==', this.academyId)
        .where('stickerCnt', '>', this.academy.stickerCnt - 1).get()

      if (sn.size > 0) {
        this.$toast.error('스티커 갯수가 ' + this.academy.stickerCnt + '개 이상인 학생이  ' + sn.size + '명 있습니다')
        return
      }

      await this.$firebase.firestore().collection('academies').doc(this.academyId).update(updateForm)
      this.snackbar = true
    }
  }
}
</script>

<template>
  <div>
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  사용자/학원/학생
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn color="light-green" tile dark @click="search"><v-icon>mdi-magnify</v-icon> 조회</v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <v-row>
            <v-col cols="4">
              <v-card outlined tile>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle>사용자({{ usersCnt > 0 ? usersCnt : 0 }})</v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
                <v-list>
                  <v-list-item-group v-model="itemIndex" color="primary">
                    <v-list-item
                      v-for="(item, i) in users"
                      :key="i"
                      @click="selectAcademy(item, i)"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.email }} ({{ item.displayName }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>

            <v-col cols="8">
              <v-tabs
                background-color="grey lighten-4"
                color="deep-purple accent-4"
                grow
                v-model="activeTab"
              >
                <v-tab>사용자</v-tab>
                <v-tab>Academy</v-tab>
                <v-tab>학생</v-tab>
                <v-tab-item>
                  <v-card outlined tile>
                    <v-list>
                      <v-list-item>
                        <v-text-field label="ID" :value="selectedUser.id" disabled></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field label="Display name" :value="selectedUser.displayName" disabled></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field label="Accept Terms" :value="selectedUser.acceptTerms" disabled></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field label="Level" :value="selectedUser.level" disabled></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field label="Disabled" :value="selectedUser.disabled" disabled></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field label="CreatedAt" :value="selectedUser.createdAt" disabled></v-text-field>
                      </v-list-item>
                      <v-list-item>
                        <v-text-field label="LastVisitAt" :value="selectedUser.lastVisitAt" disabled></v-text-field>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <academy-card
                    :academyId="selectedUser.id"
                    :academy="selectedAcademy"
                  ></academy-card>
                </v-tab-item>
                <v-tab-item>
                  <student-card
                    :academyId="selectedUser.id"
                    :students="students"
                    :studentsCnt="studentsCnt"
                    :emojis="emojis"
                  ></student-card>
                </v-tab-item>
              </v-tabs>

            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AcademyCard from './components/AcademyCard'
import StudentCard from './components/StudentCard'

export default {
  components: { AcademyCard, StudentCard },
  data () {
    return {
      users: [],
      usersCnt: 0,
      itemIndex: 0,
      selectedUser: {},
      activeTab: 0,
      selectedAcademy: {
        name: '',
        type: '',
        stickerCnt: 0,
        stickerReward: 0
      },
      students: [],
      studentsCnt: 0,
      emojis: []
    }
  },
  methods: {
    async search () {
      this.$firebase.firestore().collection('users')
        .orderBy('email')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.users = []
          }
          this.usersCnt = sn.size

          this.users = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              email: item.email,
              emailVerified: item.emailVerified,
              displayName: item.displayName,
              acceptTerms: item.acceptTerms,
              disabled: item.disabled,
              level: item.level,
              createdAt: item.createdAt.toDate(),
              lastVisitAt: item.visitedAt.toDate()
            }
          })
          // console.log(JSON.stringify(this.students))
        })
    },
    selectAcademy (item, index) {
      this.selectedUser = item

      this.getAcademy()
      this.getStudents()
      this.getEmojis()
    },
    getAcademy () {
      if (!this.selectedUser.id) return
      const r = this.$firebase.firestore().collection('academies').doc(this.selectedUser.id)
      r.get()
        .then(doc => {
          if (doc.exists) {
            this.selectedAcademy.name = doc.data().name
            this.selectedAcademy.type = doc.data().type
            this.selectedAcademy.stickerCnt = doc.data().stickerCnt
            this.selectedAcademy.stickerReward = doc.data().stickerReward
          }
        })
    },
    getStudents () {
      if (!this.selectedUser.id) return
      this.$firebase.firestore().collection('students')
        .where('academy', '==', this.selectedUser.id)
        .orderBy('name')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.students = []
          }
          this.studentsCnt = sn.size

          this.students = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name,
              grade: item.grade,
              photoURL: item.photoURL,
              photoName: item.photoName,
              stickerIcon: item.stickerIcon,
              stickerCnt: item.stickerCnt,
              pointScore: item.pointScore,
              academy: item.academy,
              status: item.status,
              createdAt: item.createdAt.toDate(),
              userid: item.userid,
              password: item.password
            }
          })
          // console.log(JSON.stringify(this.students))
        })
    },
    getEmojis () {
      this.emojis = []
      this.$firebase.firestore().collection('infos').doc('emojis').get()
        .then((r) => {
          if (r.exists) {
            for (let i = 0; i < r.data().data.length; i++) {
              this.emojis.push(r.data().data[i])
            }
          }
        })
        .catch(e => {
          console.log(e.message)
        })
    }

  }
}
</script>

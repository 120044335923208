<template>
  <div>
    <v-card outlined tile>
      <v-list>
        <v-list-item>
          <v-list-item-title>학생수 : 총 {{ studentsTotal }}명</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-select
            :items="students"
            v-model="student"
            label="학생을 선택하세요"
            item-text="name"
            @change="changeStudent"
            outlined
            return-object
            single-line
          >
            <template slot="no-data">
              <v-alert>학생이 없습니다</v-alert>
            </template>
          </v-select>
        </v-list-item>
        <v-list-item>
          <v-text-field label="성명" v-model="form.name"></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-select
            v-model="form.grade"
            :items="gradeItems"
            label="학년"
          ></v-select>
        </v-list-item>
        <v-list-item>
          <v-menu offset-y max-width="324">
            <template v-slot:activator="{ on }">
              <v-img>
                <v-icon color="pink">{{ form.stickerIcon }}</v-icon>
                <v-btn v-on="on" text height="70"><span class="subtitle-1 grey--text">스티커 모양 변경</span></v-btn>
              </v-img>
            </template>
            <v-card
              class="d-flex flex-wrap" max-width="324"
            >
              <v-card
                v-for="(item, i) in emojis"
                :key="i"
                flat
                class="pa-3"
              >
                <v-icon
                  size="30"
                  color="pink"
                  @click="clickEmoji(item.name)"
                >{{ item.name }}</v-icon>
              </v-card>
            </v-card>
          </v-menu>
        </v-list-item>
        <v-list-item>
          <v-text-field label="학생의 스티커 갯수" v-model="form.stickerCnt"></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-text-field label="학생이 보유한 포인트" v-model="form.pointScore"></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-select
            v-model="form.status"
            :items="statusItems"
          ></v-select>
        </v-list-item>
        <v-list-item>
          <v-text-field label="로그인 아이디" v-model="form.userid"></v-text-field>
        </v-list-item>
        <v-list-item>
          <v-text-field label="로그인 패스워드" v-model="form.password"></v-text-field>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn tile outlined @click="clear"><v-icon>mdi-autorenew</v-icon>신규</v-btn>
        <v-spacer/>
        <v-btn tile outlined color="success" @click="updateStd"><v-icon>mdi-content-save-edit</v-icon>수정</v-btn>
        <v-btn tile outlined color="error" @click="delDialog"><v-icon>mdi-delete</v-icon>삭제</v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      color="info"
    >
      처리 완료!

      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          닫기
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">학생 삭제</v-card-title>
        <v-card-text>선택한 학생을 삭제하시겠습니까?</v-card-text>
        <v-card-subtitle>{{ dialogName }} ({{ dialogGrade }})</v-card-subtitle>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="cancel">취소</v-btn>
          <v-btn color="error" text @click="delStd">삭제</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Utils from '@/utils'

export default {
  props: ['academyId', 'students', 'studentsCnt', 'emojis'],
  data () {
    return {
      student: null,
      form: {
        name: '',
        grade: '유치',
        // photoName: '',
        // photoURL: '',
        stickerIcon: 'mdi-cat',
        stickerCnt: null,
        pointScore: null,
        academy: '',
        status: '정상',
        createdAt: null,
        userid: '',
        password: ''
      },
      gradeItems: [
        '유치', '초1', '초2', '초3', '초4', '초5', '초6', '중1', '중2', '중3', '고1', '고2', '고3'
      ],
      statusItems: ['정상', '휴원', '퇴원'],
      statusToggle: 'ADD',
      snackbar: false,
      dialog: false,
      dialogName: '',
      dialogGrade: ''
    }
  },
  computed: {
    studentsTotal () {
      return this.studentsCnt ? this.studentsCnt : 0
    }
  },
  methods: {
    clickEmoji (name) {
      this.form.stickerIcon = name
    },
    changeStudent () {
      this.statusToggle = 'EDIT'

      this.form.name = this.student.name
      this.form.grade = this.student.grade
      // this.form.photoName = this.student.photoName
      // this.form.photoURL = this.student.photoURL
      this.form.stickerIcon = this.student.stickerIcon
      this.form.stickerCnt = this.student.stickerCnt
      this.form.pointScore = this.student.pointScore
      this.form.academy = this.student.academy
      this.form.status = this.student.status
      this.form.createdAt = this.student.createdAt
      this.form.userid = this.student.userid
      this.form.password = Utils.DEC(this.student.password)
    },
    clear () {
      this.statusToggle = 'ADD'
      this.student = null
      this.form = {
        name: '',
        grade: '유치',
        stickerIcon: 'mdi-cat',
        stickerCnt: null,
        pointScore: null,
        academy: '',
        status: '정상',
        createdAt: null,
        userid: '',
        password: ''
      }
    },
    async updateStd () {
      if (!this.form.name || !this.form.stickerCnt || !this.form.pointScore) {
        this.$toast.error('입력값이 부족합니다.')
        return
      }

      if (await this.dupAccountCnt(this.statusToggle) > 0) {
        this.$toast.error('이미 사용중인 id 입니다.')
        return
      }

      this.form.userid = this.form.userid.trim()
      if (this.form.userid.length <= 0) {
        this.form.password = ''
      }

      if (this.statusToggle === 'ADD') {
        this.form.createdAt = new Date()
        await this.$firebase.firestore().collection('students').add({
          name: this.form.name,
          grade: this.form.grade,
          photoName: '',
          photoURL: '',
          stickerIcon: this.form.stickerIcon,
          stickerCnt: Number(this.form.stickerCnt),
          pointScore: Number(this.form.pointScore),
          academy: this.academyId,
          status: this.form.status,
          createdAt: this.form.createdAt,
          userid: this.form.userid,
          password: Utils.ENC(this.form.password)
        })
      } else {
        await this.$firebase.firestore().collection('students').doc(this.student.id).update({
          name: this.form.name,
          grade: this.form.grade,
          stickerIcon: this.form.stickerIcon,
          stickerCnt: Number(this.form.stickerCnt),
          pointScore: Number(this.form.pointScore),
          academy: this.form.academy,
          status: this.form.status,
          // createdAt: this.form.createdAt,
          userid: this.form.userid,
          password: Utils.ENC(this.form.password)
        })
      }

      this.snackbar = true
    },
    async dupAccountCnt (type) {
      let cnt = 0
      if (this.form.userid.trim().length <= 0) {
        return cnt
      }

      const sn = await this.$firebase.firestore().collection('students')
        .where('userid', '==', this.form.userid.trim()).get()

      if (sn.empty) {
        return cnt
      }

      if (type === 'ADD') {
        cnt = sn.size
      } else {
        sn.docs.forEach(doc => {
          if (doc.id !== this.student.id) {
            cnt = cnt + 1
          }
        })
      }

      return cnt
    },
    delDialog () {
      if (!this.student) return

      this.dialog = true
      this.dialogName = this.student.name
      this.dialogGrade = this.student.grade
    },
    async delStd () {
      this.dialog = false
      this.dialogName = ''
      this.dialogGrade = ''

      await this.$firebase.firestore().collection('students').doc(this.student.id).delete()

      this.clear()
    },
    cancel () {
      this.dialog = false
    }
  }
}
</script>
